<template>
  <div class="container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="文章分类管理" name="first">
        <el-form
          :inline="true"
          :model="formInline"
          ref="formInline"
          label-position="right"
          class="demo-form-inline"
          label-width="90px"
        >
          <el-form-item label="分类状态：" prop="categoryStatus">
            <el-select
              v-model="formInline.categoryStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in categoryStatusList"
                :key="index"
                :label="
                  item == 0 ? '申请发布' : item == 1 ? '申请删除' : '已发布'
                "
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="button_left">
            <el-button type="primary" @click="onSubmit" icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              class="button_reset"
              @click="resetForm('formInline')"
              plain
              icon="el-icon-refresh"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item class="button_right">
            <el-button
              @click="
                drawer = true;
                drawerTitle = '添加分类';
              "
              type="primary"
              icon="el-icon-circle-plus-outline"
            >
              添加分类
            </el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="multipleTable"
          :data="formattedCategoryList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :height="height"
          size="mini"
        >
          <!-- <el-table-column type="selection" width="55" fixed> </el-table-column> -->
          <el-table-column type="index" width="60" label="序号" fixed>
          </el-table-column>
          <el-table-column prop="category_name" label="分类名">
          </el-table-column>
          <el-table-column prop="category_introduction" label="分类简介">
          </el-table-column>
          <el-table-column
            prop="category_status"
            label="分类状态"
            min-width="100"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.category_status == 0">申请发布</span>
              <span v-else-if="scope.row.category_status == 1">申请删除</span>
              <span v-else>已发布</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="category_create_time"
            label="创建时间"
            :width="tabelColWidth"
          >
          </el-table-column>

          <el-table-column label="操作" fixed="right" width="220">
            <template slot-scope="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定发布吗？"
                v-show="
                  scope.row.category_status == 0 && myUserInfo.user_id == 1
                "
                @confirm="handlePublish(scope.$index, scope.row)"
              >
                <el-button slot="reference" type="text" class="red">
                  发布
                </el-button>
              </el-popconfirm>

              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                title="确定取消发布吗？"
                v-show="
                  scope.row.category_status == 2 && myUserInfo.user_id == 1
                "
                @confirm="handlePublish(scope.$index, scope.row)"
              >
                <el-button slot="reference" class="button_public" type="text">
                  取消发布
                </el-button>
              </el-popconfirm>

              <el-button
                type="text"
                @click="
                  handleEdit(scope.$index, scope.row);
                  drawerTitle = '编辑';
                "
                v-show="myUserInfo.user_id == 1"
              >
                编辑
              </el-button>

              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定取消删除申请吗?"
                v-show="scope.row.category_status == 1"
                @confirm="handleRecover(scope.$index, scope.row)"
              >
                <el-button type="text" slot="reference">
                  取消删除申请
                </el-button>
              </el-popconfirm>

              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                cancel-button-type="Primary"
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                v-show="scope.row.category_status != 1"
                @confirm="handleDelete(scope.$index, scope.row)"
              >
                <el-button type="text" slot="reference" class="red">
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <el-drawer
          :title="drawerTitle"
          :visible.sync="drawer"
          :with-header="true"
          :before-close="handleClose"
          size="39%"
          :destroy-on-close="true"
        >
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            v-show="drawerTitle == '添加分类'"
          >
            <el-form-item label="分类名称" prop="categoryName">
              <el-input
                v-model="ruleForm.categoryName"
                placeholder="请填写分类名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="分类简介" prop="categoryIntroduction">
              <el-input
                type="textarea"
                v-model="ruleForm.categoryIntroduction"
                placeholder="请填写分类简介"
              ></el-input>
            </el-form-item>

            <el-form-item class="right">
              <el-button @click="cancel" plain>取消</el-button>
              <el-button type="primary" @click="confirm('ruleForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
          <el-form
            :model="ruleForm2"
            :rules="rules"
            ref="ruleForm2"
            label-width="120px"
            v-show="drawerTitle == '编辑'"
          >
            <el-form-item label="分类名称" prop="categoryName">
              <el-input
                v-model="ruleForm2.categoryName"
                placeholder="请填写分类名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="分类简介" prop="categoryIntroduction">
              <el-input
                type="textarea"
                v-model="ruleForm2.categoryIntroduction"
                placeholder="请填写分类简介"
              ></el-input>
            </el-form-item>

            <el-form-item class="right">
              <el-button @click="cancel" plain>取消</el-button>
              <el-button type="primary" @click="confirm('ruleForm2')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </el-drawer>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "CategoryManage",
  data() {
    return {
      disabled: false,
      height: 740,
      drawerTitle: "",
      drawer: false,
      activeName: "first",
      categoryStatusList: [0, 1, 2],
      formInline: {
        currentPage: 1,
        pageSize: 10,
        enabledStatus: null,
        accountStatus: null,
        roleId: null,
        categoryStatus: null,
      },
      ruleForm: {
        categoryName: null,
        categoryIntroduction: null
      },
      ruleForm2: {
        categoryName: null,
        categoryIntroduction: null,
        categoryId: null,
      },
      rules: {
        categoryName: [
          { required: true, message: "请输入分类名", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        categoryIntroduction: [
          { required: true, message: "请输入分类简介", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["myUserInfo"]),
    ...mapState("category", ["categoryList", "message", "status"]),
    ...mapGetters("category", ["formattedCategoryList"])
  },
  methods: {
    ...mapActions("category", {
      getCategory: "getCategory",
      addCategory: "addCategory",
      updateCategory: "updateCategory",
      updateCategoryStatus: "updateCategoryStatus",
      deleteCategory: "deleteCategory",
    }),
    cancel() {
      this.handleClose();
    },
    // handleClick(tab, event) {
    //   // console.log(tab, event);
    // },
    // 查询账号列表
    onSubmit() {
      let categoryStatus =
        this.formInline.categoryStatus || this.formInline.categoryStatus == 0
          ? this.formInline.categoryStatus
          : -1;
      this.getCategory({ categoryStatus });
    },
    resetForm(formName) {
      this.formData = null;
      this.formData = new FormData();
      this.$refs[formName].resetFields();
    },
    handlePublish(index, row) {
      let categoryStatus = row.category_status == 0 ? 2 : 0;
      // console.log(categoryStatus);
      this.updateCategoryStatus({
        categoryStatus,
        categoryId: row.category_id,
      }).then(() => {
        if (this.status == 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.message,
          });
          this.onSubmit();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.message,
          });
        }
      });
    },
    handleDelete(index, row) {
      let categoryId = row.category_id;
      if (categoryId) {
        this.deleteCategory({ categoryId }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleRecover(index, row) {
      let categoryId = row.category_id;
      let categoryStatus = 0;
      if (categoryId) {
        this.updateCategoryStatus({ categoryId, categoryStatus }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    // 编辑
    handleEdit(index, row) {
      // this.ruleForm2.avatar = row.avatar;
      this.ruleForm2.categoryId = row.category_id;
      this.ruleForm2.categoryName = row.category_name;
      this.ruleForm2.categoryIntroduction = row.category_introduction;
      this.drawer = true;
      // console.log(this.ruleForm2);
      // console.log(row);
      // this.content = row.content;
    },
    // handleAvatarSuccess(res, file) {
    //   console.log(file);
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    handleClose(done) {
      this.$confirm("确认关闭？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.drawer) {
            this.resetForm("ruleForm");
            this.resetForm("ruleForm2");
            this.drawer = false;
          }
          if (this.dialogFormVisible) {
            this.resetForm("form");
            this.dialogFormVisible = false;
          }
          if (this.dialogFormVisible2) {
            this.resetForm("form2");
            this.dialogFormVisible2 = false;
          }
          done();
        })
        .catch(() => {});
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.drawerTitle === "添加分类") {
            // console.log(this.ruleForm);
            // 调用添加 接口方法
            this.addCategory({
              categoryName: this.ruleForm.categoryName,
              categoryIntroduction: this.ruleForm.categoryIntroduction,
            }).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
          if (this.drawerTitle === "编辑") {
            // 调用编辑接口方法
            this.updateCategory({
              categoryName: this.ruleForm2.categoryName,
              categoryIntroduction: this.ruleForm2.categoryIntroduction,
              categoryId: this.ruleForm2.categoryId,
            }).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请先填写好表单!",
          });
          return false;
        }
      });
    },
  },
  mounted() {
    this.onSubmit();
    this.$nextTick(function () {
      this.height =
        window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 220;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function () {
        if (self.$refs.multipleTable) {
          self.height =
            window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 220;
        }
      };
    });
  },
};
</script>

<style lang="less" scoped>
@color: #409eff;
.right {
  float: right;
}
.red {
  color: #f56c6c;
}
.container {
  .el-tabs {
    /deep/ #tab-first {
      font-weight: 550;
      font-size: 1.25rem;
    }
    .demo-form-inline {
      /deep/ .el-input__inner {
        width: 14.375rem;
      }
      /deep/ .el-form-item__label {
        padding: 0;
        font-size: 18px;
      }
      .button_right {
        float: right;
        .el-button {
          margin-left: 0.625rem;
        }
      }
    }
    /deep/ .el-table {
      font-size: 16px;
      .el-button {
        font-size: 16px;
        margin: 0 10px 0 0;
      }
      .is_show {
        display: block;
      }
      .el-table__fixed-right::before,
      .el-table__fixed::before {
        height: 0;
      }
      &::before {
        display: none;
      }
    }

    /deep/ .el-drawer__header {
      font-size: 20px;
      color: #333333;
      overflow: hidden;
      &:after {
        width: 93%;
        height: calc(100% - 0.1rem);
        background-image: linear-gradient(
          to right,
          #ccc 0%,
          #ccc 50%,
          transparent 50%
        );
        background-size: 10px 1px;
        background-repeat: repeat-x;
        position: absolute;
        top: 3em;
        content: "";
      }
    }
    /deep/ .el-drawer__body {
      padding: 10px 24px;
      margin-bottom: 20px;
      .el-form-item__label {
        font-size: 18px;
      }
      .zindex_max {
        z-index: 100000;
      }
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
